<script setup lang="ts">
import {getTranslatedProperty, getCategoryRoute} from "@shopware-pwa/helpers-next";
import {Category, Media} from "@shopware-pwa/types";
import Cookies from "js-cookie";

const {navigationElements} = useNavigation();

const isSideMenuOpened = inject("isSideMenuOpened", ref(false));
const expandedIds = ref<Array<string>>([]);

function isCollapsed(navigationelement: Category): boolean {
    return !expandedIds.value.includes(navigationelement.id);
}

const sideMenuElement = ref(null);

const toggleCollapse = (navigationElement: Category) => {
    if (!isCollapsed(navigationElement)) {
        expandedIds.value = expandedIds.value.filter(
            (el) => el !== navigationElement.id
        );
    } else {
        expandedIds.value.push(navigationElement.id);
    }
};
const getItemImage = (media: Media|null) => {
    return media?.url ?? "/product_placeholder.webp";
};
const storeMode = useCookie("mode");
</script>

<template>
    <Transition>
        <div
            v-if="isSideMenuOpened"
            class="relative z-50"
            role="dialog"
            aria-modal="true"
            data-test-id="side-menu-container"
        >
            <div class="fixed lg:hidden inset-0 z-40 flex mt-[95px] max-w-[500px]">
                <div
                    ref="sideMenuElement"
                    class="relative flex flex-col w-full overflow-y-auto bg-white shadow-xl"
                    style="padding-bottom:80px;"
                >
                    <div class="max-w-2xl" :class="{'bg-brand-lightGold': expandedIds.length > 0}">
                        <aside aria-label="Sidebar">
                            <div class="overflow-y-auto">
                                <ul class="flex flex-col p-0 space-y-2 list-none">
                                    <li
                                        v-for="navigationElement in navigationElements"
                                        :key="navigationElement.id"
                                        class="!mt-0"
                                    >
                                        <template v-if="(navigationElement.children?.length > 0)">
                                            <div
                                                class="pb-[7px] pt-[7px] px-5 flex justify-between w-full box-border"
                                                :class="{'text-brand-primary font-semibold': !isCollapsed(navigationElement), 'font-medium': isCollapsed(navigationElement)}"
                                                @click.stop.prevent="toggleCollapse(navigationElement)"
                                                data-test-id="side-menu-first-level-with-children"
                                            >
                                                <div class="flex items-center text-4">
                                                    <div class="w-[41px] flex justify-center">
                                                        <NuxtImg loading="lazy" :src="getItemImage(navigationElement.media)" height="41" />
                                                    </div>
                                                    <span class="ml-[16px]"  style="font-size: 17px">{{ getTranslatedProperty(navigationElement, "name") }}</span>
                                                </div>
                                                <div
                                                    class="flex items-center"
                                                    :class="{
                                                        'text-brand-primary': !isCollapsed(navigationElement),
                                                        'text-black': isCollapsed(navigationElement)
                                                    }">
                                                    <svg id="arrow-left-bold" :class="{ 'expanded w-[9px]': !isCollapsed(navigationElement) }" class="mr-1 dropdown-arrow" fill="currentColor" xmlns="http://www.w3.org/2000/svg" height="9" viewBox="0 0 6.613 12.428">
                                                        <path id="Path_1170" data-name="Path 1170" d="M40.907,16.607a.988.988,0,0,0,0,1.32l4.275,4.621-4.275,4.621a.987.987,0,0,0,0,1.32.819.819,0,0,0,1.221,0l4.886-5.281a.988.988,0,0,0,0-1.32l-4.886-5.281A.819.819,0,0,0,40.907,16.607Z" transform="translate(-40.653 -16.333)"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <NuxtLink
                                                :target="(navigationElement.externalLink || navigationElement.linkNewTab) ? '_blank' : ''"
                                                :to="getCategoryRoute(navigationElement)"
                                                @click="isSideMenuOpened = false"
                                                class="pb-[7px] pt-[7px] px-5 flex justify-between w-full box-border font-medium"
                                            >
                                                <div class="flex items-center text-4">
                                                    <div class="w-[41px] flex justify-center">
                                                        <NuxtImg loading="lazy" :src="getItemImage(navigationElement.media)" height="41" />
                                                    </div>
                                                    <span class="ml-[16px]"  style="font-size: 17px">{{ getTranslatedProperty(navigationElement, "name") }}</span>
                                                </div>
                                            </NuxtLink>
                                        </template>
                                        <ul
                                            v-if="
                                                navigationElement?.children?.length &&
                                                !isCollapsed(navigationElement)
                                              "
                                            class="py-2 m-0 list-none px-0 bg-white"
                                            data-test-id="side-menu-second-level"
                                        >
                                            <li>
                                                <NuxtLink
                                                    :target="(navigationElement.externalLink || navigationElement.linkNewTab) ? '_blank' : ''"
                                                    :to="getCategoryRoute(navigationElement)"
                                                    @click="isSideMenuOpened = false"
                                                    class="font-semibold pl-[75px] py-3 inline-block italic"
                                                >
                                                    <span class="border-b border-b-solid">{{ $t('general.seeAll') }} {{getTranslatedProperty(navigationElement, "name")}}</span>
                                                </NuxtLink>
                                            </li>
                                            <li
                                                v-for="childElement in navigationElement.children"
                                                :key="childElement.id"
                                            >
                                                <NuxtLink
                                                    :target="(childElement.externalLink || childElement.linkNewTab) ? '_blank' : ''"
                                                    :to="getCategoryRoute(childElement)"
                                                    @click="isSideMenuOpened = false"
                                                    class="px-5 pb-[7px] pt-[7px] inline-block"
                                                >
                                                    <div class="flex items-center text-4 font-medium">
                                                        <div class="w-[41px] flex justify-center">
                                                            <NuxtImg loading="lazy" :src="getItemImage(childElement.media)" height="41" />
                                                        </div>
                                                        <span class="ml-[16px]">{{ getTranslatedProperty(childElement, "name") }}</span>
                                                    </div>
                                                </NuxtLink>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </aside>
                    </div>
                </div>
                <div class="fixed w-full h-[80px] bg-brand-flatGray bottom-0 py-[20px] px-4">
                    <div class="flex rounded-full bg-white m-auto max-w-[350px] p-[3px] h-[40px] text-center">
                        <a
                            href="/?mode=privat"
                            class="block py-[6px] w-[84px] w-1/2 rounded-full"
                            :class="{'bg-brand-bottleGreen text-white font-semibold': !storeMode || storeMode == 'privat'}">
                            <span>{{ $t('account.private') }}</span>
                        </a>
                        <a
                            href="/erhvervskonto/?mode=erhverv"
                            class="block py-[6px] w-[84px] w-1/2 rounded-full"
                            :class="{'bg-brand-bottleGreen text-white font-semibold': storeMode == 'erhverv'}">
                            <span>{{ $t('account.business') }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>
